import api from '../axios/api';

export const getDashboardCount = async () => {
  const { data } = await api.get('dashboard/count');
  return data;
};

export const getDashboardSales = async () => {
  const { data } = await api.get('dashboard/sales');
  return data;
};

export const getDashboardTasks = async () => {
  const { data } = await api.get('dashboard/tasks');
  return data;
};

export const getDashboardProductCategory = async () => {
  const { data } = await api.get('dashboard/productCategory');
  return data;
};

import { Grid, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getDashboardSales } from '../../api/dashboardApi';
import { AppCurrentVisits } from '../../sections/@dashboard/app';

const DashboardSales = () => {
  const theme = useTheme();
  const { data: sales } = useQuery(['dashboardSales'], getDashboardSales);
  let grandTotal = 0;
  if (sales) {
    grandTotal = [
      ...sales?.billingTotal,
      ...sales?.barBillingTotal,
      ...sales?.jewelleryInventoryBillingTotal,
      ...sales?.watchInventoryBillingTotal,
      ...sales?.silverInventoryBillingTotal,
    ]?.reduce((acc, { total }) => acc + total, 0);
  }

  const totalSales = `Total Sales Rs ${grandTotal?.toFixed(3)}`;
  return (
    <>
      {sales && (
        <Grid item xs={12} md={6} lg={4}>
          <AppCurrentVisits
            title={totalSales}
            chartData={[
              { label: 'Jewellery Orders', value: Number(sales?.billingTotal[0]?.total?.toFixed(2)) },
              { label: 'Bar Orders', value: Number(sales?.barBillingTotal[0]?.total?.toFixed(2)) },
              { label: 'Jewellery', value: Number(sales?.jewelleryInventoryBillingTotal[0]?.total?.toFixed(2)) },
              { label: 'Watch', value: Number(sales?.watchInventoryBillingTotal[0]?.total?.toFixed(2)) },
              { label: 'Silver', value: Number(sales?.silverInventoryBillingTotal[0]?.total?.toFixed(2)) },
            ]}
            chartColors={[
              theme.palette.primary.main,
              theme.palette.info.main,
              theme.palette.warning.main,
              theme.palette.success.main,
              theme.palette.error.main,
            ]}
          />
        </Grid>
      )}
    </>
  );
};

export default DashboardSales;

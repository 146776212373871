// export const preset = 'toclqyod';
// export const cloud = 'https://api.cloudinary.com/v1_1/ddkv32qqz/image/upload';

export const paymentTypes = [
  { value: 'Cash' },
  { value: 'Credit Card' },
  { value: 'Online' },
  { value: 'Gold' },
  { value: 'Cheque' },
];

// api urls

// export const BASE_URL = 'http://localhost:3500/api/v1/';
// export const IMG_URL = 'http://localhost:3500';

export const BASE_URL = 'https://backend.gurkhawatch.com/api/v1/';
export const IMG_URL = 'https://backend.gurkhawatch.com';

// export const BASE_URL = 'https://jewellerycrm-server.onrender.com/api/v1/';
// export const IMG_URL = 'https://jewellerycrm-server.onrender.com';

// api urls

// application constants
// export const currency = [{ value: 'UK Pound' }];

export const currency = [{ value: 'Nepalese Rupee' }];
export const karatType = [{ value: '18' }, { value: '22' }, { value: '24' }];
export const metalTypes = [
  { name: 'Gold', value: 'Gold' },
  { name: 'Silver', value: 'Silver' },
];
export const priorityTypes = [{ value: 'High' }, { value: 'Medium' }, { value: 'Low' }];
export const deliveryOption = [{ value: 'Self Pickup' }, { value: 'Dispatch' }];
export const orderSource = [{ value: 'Instagram' }, { value: 'Facebook' }, { value: 'Website' }];
export const repairStatus = [
  { value: 'received by customer' },
  { value: 'received (but not sent for repair)' },
  { value: 'sent for repair' },
  { value: 'repaired and kept in office' },
  { value: 'received by customer' },
];
export const productCategoryType = [{ value: 'main category' }, { value: 'sub category' }];

import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getDashboardCount } from '../../api/dashboardApi';
import LegacyLinks from '../../components/legacyLinks/LegacyLinks';
import { AppWidgetSummary } from '../../sections/@dashboard/app';
// import DashboardTasks from './DashboardTasks';
import DashboardSales from './DashboardSales';
import DashboardProductCategory from './DashboardProductCategory';

const DashboardAdminPage = () => {
  const { data } = useQuery(['dashboardCount'], getDashboardCount);
  const navigate = useNavigate();

  const links = [
    { name: 'Users', to: '/dashboard/users', icon: 'mdi:users' },
    { name: 'Customers', to: '/dashboard/customers', icon: 'raphael:people' },
    { name: 'Factory', to: '/dashboard/factory', icon: 'material-symbols:factory' },
    { name: 'Orders', to: '/dashboard/order-links', icon: 'mingcute:pencil-ruler-fill' },
    { name: 'Process-list', to: '/dashboard/order/process', icon: 'fa-solid:sync' },
    { name: 'Billings', to: '/dashboard/order/billing', icon: 'fa6-solid:file-invoice-dollar' },
    { name: 'Dispatch', to: '/dashboard/order/dispatch', icon: 'mdi:courier-check' },
    { name: 'Inventory', to: '/dashboard/inventory-links', icon: 'ic:baseline-inventory' },
    { name: 'Reports', to: '/dashboard/report', icon: 'iconoir:reports' },
    { name: 'Profile', to: '/dashboard/profile', icon: 'fa-solid:user-cog' },
    { name: 'Cancelled Invoice', to: '/dashboard/cancel', icon: 'material-symbols:delete' },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            onClick={() => navigate('/dashboard/inventory/watch')}
            title="Total No. of Watches"
            total={data?.Watch}
            icon={'mdi:watch-variant'}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            onClick={() => navigate('/dashboard/inventory/watch')}
            title="Total No. of Accessories"
            total={data?.Accessories}
            color="info"
            icon={'game-icons:prayer-beads'}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            onClick={() => navigate('/dashboard/inventory/pre-order')}
            title="Total No. of Pre order"
            total={data?.PreOrder}
            color="warning"
            icon={'carbon:book'}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title="Total No. of Sales"
            total={data?.productSales[0]?.total}
            color="error"
            icon={'clarity:dollar-bill-solid'}
          />
        </Grid>
        {/* product category  */}
        <DashboardProductCategory />
        {/* sales chart  */}
        <DashboardSales />
        {/* TASKS COMPLETED AND NOT COMPLETED */}
        {/* <DashboardTasks /> */}
      </Grid>
      {/* legacy links  */}
      <LegacyLinks links={links} />
    </>
  );
};

export default DashboardAdminPage;

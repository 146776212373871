import { Box, Button, ButtonGroup, Card, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { getDashboardProductCategory } from '../../api/dashboardApi';
import { getAllPreOrder } from '../../api/preOrderApi';

const DashboardProductCategory = () => {
  const [active, setActive] = useState('');
  const [subCategoryOf, setSubCategoryOf] = useState([]);
  const [activeCat, setActiveCat] = useState('');
  const [filteredProduct, setFilteredProduct] = useState([]);

  const { data } = useQuery(['dashboardProductCategory'], getDashboardProductCategory);
  const { data: preOrder } = useQuery(['preorder'], getAllPreOrder);

  useEffect(() => {
    if (data) {
      if (data?.mainCategory?.length > 0) {
        const { mainCategory } = data;
        setActive(mainCategory[0]?._id);
        const currentSelection = data?.subCategoryWithCount.filter((cat) => cat.categoryId === mainCategory[0]?._id);
        setSubCategoryOf(currentSelection);
      }
    }
  }, [data]);

  const handleClick = (id) => {
    setActive(id);
    setActiveCat('');
    setFilteredProduct([]);
    if (id === 'pre order') {
      const watch = data?.mainCategory.find((cat) => cat.name === 'Watches');
      const currentSelection = data?.subCategoryWithCount.filter((cat) => cat.categoryId === watch?._id) ?? [];
      let currentSelectionWithPreOrder = [];

      if (currentSelection.length > 0) {
        currentSelectionWithPreOrder = currentSelection.map((item) => {
          const productToSend = [];
          const filteredProducts = data?.productsName?.filter((product) => product.categoryId === item._id);

          filteredProducts?.forEach((p) => {
            const matchingProducts = preOrder.filter((pre) => pre.name === p.watchName);
            let waitingCount = 0;
            let billingCount = 0;
            let refundedCount = 0;
            const count = matchingProducts.length;
            if (matchingProducts.length > 0) {
              waitingCount = matchingProducts.filter((pro) => pro.status === 'Waiting').length;
              billingCount = matchingProducts.filter((pro) => pro.status === 'Billing').length;
              refundedCount = matchingProducts.filter((pro) => pro.status === 'Refunded').length;
            }
            productToSend.push({ ...p, waitingCount, billingCount, refundedCount, count, name: p.watchName });
          });

          return {
            ...item,
            products: productToSend,
          };
        });
      }
      // console.log(currentSelectionWithPreOrder);
      setSubCategoryOf(currentSelectionWithPreOrder);
    } else {
      const currentSelection = data?.subCategoryWithCount.filter((cat) => cat.categoryId === id);
      setSubCategoryOf(currentSelection);
    }
  };

  const handleCatClick = (id, cat) => {
    setActiveCat(id);
    setFilteredProduct(cat.products ?? []);
  };

  return (
    <Grid item xs={12} md={8} lg={8}>
      <Card sx={{ p: 4 }}>
        <ButtonGroup sx={{ mb: 1 }}>
          {data?.mainCategory?.map((cat) => (
            <Button
              variant={active === cat._id ? 'contained' : 'outlined'}
              key={cat._id}
              onClick={() => handleClick(cat._id)}
            >
              {cat.name}
            </Button>
          ))}
          <Button variant={active === 'pre order' ? 'contained' : 'outlined'} onClick={() => handleClick('pre order')}>
            Pre Order
          </Button>
        </ButtonGroup>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {subCategoryOf.map((cat) => (
              <Box
                key={cat?._id}
                sx={{
                  marginY: 2,
                  padding: 1,
                  boxShadow: '2px 2px 2px 2px #f3f3f3',
                  borderRadius: '6px',
                  backgroundColor: activeCat === cat?._id ? '#f3f3f3' : '#fff',
                }}
                onClick={() => handleCatClick(cat._id, cat)}
              >
                <Stack spacing={2} direction="row">
                  <Typography variant="subtitle2">{cat?.name} </Typography>
                  {/* <Typography
                    variant="div"
                    sx={{ color: 'grey', fontSize: '12px', fontWeight: 'bold', display: 'block' }}
                  >
                    ( sub categories: {cat?.subCategoryCount} )
                  </Typography> */}
                </Stack>
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            {filteredProduct.map((cat) => (
              <Box
                key={cat?._id}
                sx={{
                  marginY: 2,
                  padding: 1,
                  boxShadow: '2px 2px 2px 2px #f3f3f3',
                  borderRadius: '6px',
                }}
              >
                <Typography variant="subtitle2">{cat?.name} </Typography>

                <Stack spacing={2} direction="row">
                  {active !== 'pre order' && (
                    <Typography
                      variant="div"
                      sx={{ color: 'grey', fontSize: '12px', fontWeight: 'bold', display: 'block' }}
                    >
                      Price: RS {cat?.price}
                    </Typography>
                  )}
                  <Typography
                    variant="div"
                    sx={{ color: 'grey', fontSize: '12px', fontWeight: 'bold', display: 'block' }}
                  >
                    {active === 'pre order' ? `total pre-order: ${cat?.count}` : `total inventory: ${cat?.quantity}`}
                  </Typography>

                  {active === 'pre order' && (
                    <>
                      <Typography
                        variant="div"
                        sx={{ color: 'grey', fontSize: '12px', fontWeight: 'bold', display: 'block' }}
                      >
                        waiting: {cat.waitingCount}
                      </Typography>
                      <Typography
                        variant="div"
                        sx={{ color: 'grey', fontSize: '12px', fontWeight: 'bold', display: 'block' }}
                      >
                        billing: {cat.billingCount}
                      </Typography>
                      <Typography
                        variant="div"
                        sx={{ color: 'grey', fontSize: '12px', fontWeight: 'bold', display: 'block' }}
                      >
                        refunded: {cat.refundedCount}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default DashboardProductCategory;

import { Suspense, lazy, useContext } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import ErrorBoundary from './ErrorBounder';
import Loader from './components/loader/Loader';
import { AuthContext } from './context/AuthContext';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import DashboardAppPage from './pages/DashboardAppPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import InventoryLinks from './pages/inventoryLinks/InventoryLinks';
import OrderLists from './pages/orderLists/OrderLists';
import PreOrderReceiptPage from './pages/preOrder/PreOrderReceipt';

const UpdateProductCategoryPage = lazy(() => import('./pages/productCategory/UpdateProductCategory'));
const ViewProductCategoryPage = lazy(() => import('./pages/productCategory/ViewProductCategory'));
const AddProductCategoryPage = lazy(() => import('./pages/productCategory/AddProductCategoryPage'));
const ProductCategoryPage = lazy(() => import('./pages/productCategory/ProductCategoryPage'));
const RepairPage = lazy(() => import('./pages/repair/RepairPage'));
const AddRepiarPage = lazy(() => import('./pages/repair/AddRepairPage'));
const SingleRepairPage = lazy(() => import('./pages/repair/SingleRpairPage'));
const EditRepairPage = lazy(() => import('./pages/repair/EditRepairPage'));
const IssuePage = lazy(() => import('./pages/issue/IssuePage'));
const AddIssuePage = lazy(() => import('./pages/issue/AddIssuePage'));
const EditIssuePage = lazy(() => import('./pages/issue/EditIssuePage'));
const SingleIssuePage = lazy(() => import('./pages/issue/SingleIssuePage'));
const PaymentTypePage = lazy(() => import('./pages/paymentTypes/PaymentTypePage'));
const AddPaymentTypePage = lazy(() => import('./pages/paymentTypes/AddPaymentTypePage'));
const EditPaymentTypePage = lazy(() => import('./pages/paymentTypes/EditPaymentTypePage'));
const SinglePaymentTypePage = lazy(() => import('./pages/paymentTypes/SinglePaymentTypePage'));
const WatchDispatchPage = lazy(() => import('./pages/watchDispatch/WatchDispatchPage'));
const AddWatchDispatchPage = lazy(() => import('./pages/watchDispatch/AddWatchDispatchPage'));
const SingleWatchDispatchPage = lazy(() => import('./pages/watchDispatch/SingleWatchDispatchPage'));
const UpdateWatchDispatchPage = lazy(() => import('./pages/watchDispatch/UpdateWatchDispatchPage'));
const SocialMediaPage = lazy(() => import('./pages/socialMedia/SocialMediaPage'));
const AddSocialMediaPage = lazy(() => import('./pages/socialMedia/AddSocialMediaPage'));
const EditSocialMediaPage = lazy(() => import('./pages/socialMedia/EditSocialMediaPage'));
const SingleSocialMediaPage = lazy(() => import('./pages/socialMedia/SingleSocialMediaPage'));
const WatchNamePage = lazy(() => import('./pages/watchName/WatchNamePage'));
const AddWatchNamePage = lazy(() => import('./pages/watchName/AddWatchNamePage'));
const SingleWatchNamePage = lazy(() => import('./pages/watchName/SingleWatchNamePage'));
const EditWatchNamePage = lazy(() => import('./pages/watchName/EditWatchNamePage'));
const ViewPreOrderPage = lazy(() => import('./pages/preOrder/ViewPreOrderPage'));
const EditPreOrderPage = lazy(() => import('./pages/preOrder/EditPreOrderPage'));
const PreOrder = lazy(() => import('./pages/preOrder/PreOrder'));
const AddPreOrderPage = lazy(() => import('./pages/preOrder/AddPreOrderPage'));
const CancelPage = lazy(() => import('./pages/cancel/CancelPage'));
const AddBarBillingPage = lazy(() => import('./pages/barBilling/AddBarBillingPage'));
const BarBillingBillPage = lazy(() => import('./pages/barBilling/BarBillingBillPage'));
const BarBillingHistory = lazy(() => import('./pages/barBilling/BarBillingHistory'));
const BarBillingPage = lazy(() => import('./pages/barBilling/BarBillingPage'));
const UpdateBarBillingHistory = lazy(() => import('./pages/barBilling/UpdateBarBillingHistory'));
const UpdateBarBillingPage = lazy(() => import('./pages/barBilling/UpdateBarBillingPage'));
const ViewBarBillingHistory = lazy(() => import('./pages/barBilling/ViewBarBillingHistory'));
const AddBarOrderPage = lazy(() => import('./pages/barOrder/AddBarOrderPage'));
const BarOrderPage = lazy(() => import('./pages/barOrder/BarOrderPage'));
const SingleBarPage = lazy(() => import('./pages/barOrder/SingleBarPage'));
const UpdateBarPage = lazy(() => import('./pages/barOrder/UpdateBarPage'));
const AddBillingPage = lazy(() => import('./pages/billing/AddBillingPage'));
const BillingDesign = lazy(() => import('./pages/billing/BillingDesign'));
const BillingHistory = lazy(() => import('./pages/billing/BillingHistory'));
const BillingPage = lazy(() => import('./pages/billing/BillingPage'));
const UpdateBillingHistoryPage = lazy(() => import('./pages/billing/UpdateBarBillingHistory'));
const UpdateBillingPage = lazy(() => import('./pages/billing/UpdateBillingPage'));
const ViewBillingHistoryPage = lazy(() => import('./pages/billing/ViewBillingHistory'));
const CartPage = lazy(() => import('./pages/cart/CartPage'));
const AddCustomerPage = lazy(() => import('./pages/customer/AddCustomerPage'));
const CustomerPage = lazy(() => import('./pages/customer/CustomerPage'));
const SingleCustomerPage = lazy(() => import('./pages/customer/SingleCustomerPage'));
const UpdateCustomerPage = lazy(() => import('./pages/customer/UpdateCustomerPage'));
const AddDispatchPage = lazy(() => import('./pages/dispatchPage/AddDispatchPage'));
const DispatchPage = lazy(() => import('./pages/dispatchPage/DispatchPage'));
const SingleDispatchPage = lazy(() => import('./pages/dispatchPage/SingleDispatchPage'));
const UpdateDispatchPage = lazy(() => import('./pages/dispatchPage/UpdateDispatchPage'));
const AddEmailPage = lazy(() => import('./pages/email/AddEmailPage'));
const EmailPage = lazy(() => import('./pages/email/EmailPage'));
const UpdateEmailPage = lazy(() => import('./pages/email/UpdateEmailPage'));
const AddFactoryPage = lazy(() => import('./pages/factory/AddFactoryPage'));
const FactoryPage = lazy(() => import('./pages/factory/FactoryPage'));
const SingleFactoryPage = lazy(() => import('./pages/factory/SingleFactoryPage'));
const UpdateFactoryPage = lazy(() => import('./pages/factory/UpdateFactoryPage'));
const InventoryBilling = lazy(() => import('./pages/inventoryBilling/InventoryBillilng'));
const AddJewelleryInventoryPage = lazy(() => import('./pages/jewelleryInventory/AddJewelleryInventoryPage'));
const JewelleryInventoryBilling = lazy(() => import('./pages/jewelleryInventory/JewelleryInventoryBilling'));
const JewelleryInventoryCart = lazy(() => import('./pages/jewelleryInventory/JewelleryInventoryCart'));
const JewelleryInventoryHistoryPage = lazy(() => import('./pages/jewelleryInventory/JewelleryInventoryHistoryPage'));
const JewelleryInventoryPage = lazy(() => import('./pages/jewelleryInventory/JewelleryInventoryPage'));
const SingleJewelleryInventoryPage = lazy(() => import('./pages/jewelleryInventory/SingleJewelleryPage'));
const UpdateJewelleryInventoryPage = lazy(() => import('./pages/jewelleryInventory/UpdateJewelleryInventory'));
const UpdateJewelleryInventoryCartPage = lazy(() =>
  import('./pages/jewelleryInventory/UpdateJewelleryInventoryCartPage')
);
const ViewJewelleryInventoryCartPage = lazy(() => import('./pages/jewelleryInventory/ViewJewelleryInventoryCartPage'));
const AddJewelleryOrderPage = lazy(() => import('./pages/jewelleryOrder/AddJewelleryOrderPage'));
const JewelleryOrderPage = lazy(() => import('./pages/jewelleryOrder/JewelleryOrderPage'));
const SingleJewelleryOrderPage = lazy(() => import('./pages/jewelleryOrder/SingleJewelleryOrderPage'));
const UpdateJewelleryOrderPage = lazy(() => import('./pages/jewelleryOrder/UpdateJewelleryOrderPage'));
// const LogoPage = lazy(() => import('./pages/logo/LogoPage'));
const AddProcessPage = lazy(() => import('./pages/process/AddProcessPage'));
const ProcessPage = lazy(() => import('./pages/process/ProcessPage'));
const SingleProcessPage = lazy(() => import('./pages/process/SingleProcessPage'));
const UpdateProcessPage = lazy(() => import('./pages/process/UpdateProcessPage'));
const ReportPage = lazy(() => import('./pages/report/ReportPage'));
const AddSilverPage = lazy(() => import('./pages/silver/AddSilverPage'));
const SilverCartPage = lazy(() => import('./pages/silver/SilverCartPage'));
const SilverHistoryPage = lazy(() => import('./pages/silver/SilverHistoryPage'));
const SilverPage = lazy(() => import('./pages/silver/SilverPage'));
const SilverSellBilling = lazy(() => import('./pages/silver/SilverSellBilling'));
const SingleSilverPage = lazy(() => import('./pages/silver/SingleSilverPage'));
const UpdateSilverHistoryPage = lazy(() => import('./pages/silver/UpdateSilverHIstoryPage'));
const UpdateSilverPage = lazy(() => import('./pages/silver/UpdateSilverPage'));
const ViewSilverHistoryPage = lazy(() => import('./pages/silver/ViewSilverHistoryPage'));
const AddUserPage = lazy(() => import('./pages/users/AddUserPage'));
const SingleUserPage = lazy(() => import('./pages/users/SingleUserPage'));
const UpdateProfilePage = lazy(() => import('./pages/users/UpdateProfilePage'));
const UpdateUserPage = lazy(() => import('./pages/users/UpdateUserPage'));
const UserPage = lazy(() => import('./pages/users/UserPage'));
const UserProfilePage = lazy(() => import('./pages/users/UserProfilePage'));
const AddWatchPage = lazy(() => import('./pages/watch/AddWatchPage'));
const SingleWatchPage = lazy(() => import('./pages/watch/SingleWatchPage'));
const UpdateWatchHistoryPage = lazy(() => import('./pages/watch/UpdateWatchHistoryPage'));
const UpdateWatchPage = lazy(() => import('./pages/watch/UpdateWatchPage'));
const ViewWatchHistoryPage = lazy(() => import('./pages/watch/ViewWatchHistoryPage'));
const WatchBilling = lazy(() => import('./pages/watch/WatchBilling'));
const WatchCartPage = lazy(() => import('./pages/watch/WatchCartPage'));
const WatchHistoryPage = lazy(() => import('./pages/watch/WatchHistoryPage'));
const WatchPage = lazy(() => import('./pages/watch/WatchPage'));
const TrashPage = lazy(() => import('./pages/trash/TrashPage'));

// ----------------------------------------------------------------------

const PrivateRoutes = () => {
  const { getSession } = useContext(AuthContext);
  const userDetails = getSession();
  return userDetails?.token ? <Outlet /> : <Navigate to="/login" />;
};

export default function Router() {
  const { authData } = useContext(AuthContext);

  const routes = useRoutes([
    {
      element: <PrivateRoutes />,
      children: [
        {
          path: '/dashboard',
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/dashboard/app" />, index: true },
            { path: 'app', element: <DashboardAppPage /> },
            {
              path: 'profile',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UserProfilePage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateProfilePage />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'users',
              children:
                authData?.role === 'user'
                  ? null
                  : [
                      {
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <UserPage />
                          </Suspense>
                        ),
                        index: true,
                      },
                      {
                        path: 'add',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <AddUserPage />{' '}
                          </Suspense>
                        ),
                      },
                      {
                        path: ':id/view',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <SingleUserPage />{' '}
                          </Suspense>
                        ),
                      },
                      {
                        path: ':id/edit',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <UpdateUserPage />{' '}
                          </Suspense>
                        ),
                      },
                    ],
            },
            {
              path: 'settings/email',
              children:
                authData?.role === 'user'
                  ? null
                  : [
                      {
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <EmailPage />
                          </Suspense>
                        ),
                        index: true,
                      },
                      {
                        path: 'add',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <AddEmailPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: ':id/edit',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <UpdateEmailPage />
                          </Suspense>
                        ),
                      },
                    ],
            },
            {
              path: 'settings/socialMedia',
              children:
                authData?.role === 'user'
                  ? null
                  : [
                      {
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <SocialMediaPage />
                          </Suspense>
                        ),
                        index: true,
                      },
                      {
                        path: 'add',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <AddSocialMediaPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: ':id/edit',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <EditSocialMediaPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: ':id/view',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <SingleSocialMediaPage />
                          </Suspense>
                        ),
                      },
                    ],
            },
            {
              path: 'settings/paymentType',
              children:
                authData?.role === 'user'
                  ? null
                  : [
                      {
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <PaymentTypePage />
                          </Suspense>
                        ),
                        index: true,
                      },
                      {
                        path: 'add',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <AddPaymentTypePage />
                          </Suspense>
                        ),
                      },
                      {
                        path: ':id/edit',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <EditPaymentTypePage />
                          </Suspense>
                        ),
                      },
                      {
                        path: ':id/view',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <SinglePaymentTypePage />
                          </Suspense>
                        ),
                      },
                    ],
            },
            {
              path: 'settings/issue',
              children:
                authData?.role === 'user'
                  ? null
                  : [
                      {
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <IssuePage />
                          </Suspense>
                        ),
                        index: true,
                      },
                      {
                        path: 'add',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <AddIssuePage />
                          </Suspense>
                        ),
                      },
                      {
                        path: ':id/edit',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <EditIssuePage />
                          </Suspense>
                        ),
                      },
                      {
                        path: ':id/view',
                        element: (
                          <Suspense fallback={<Loader />}>
                            {' '}
                            <SingleIssuePage />
                          </Suspense>
                        ),
                      },
                    ],
            },
            {
              path: 'factory',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <FactoryPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddFactoryPage />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleFactoryPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateFactoryPage />
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'customers',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <CustomerPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddCustomerPage />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleCustomerPage />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateCustomerPage />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'report',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <ReportPage />
                    </Suspense>
                  ),
                  index: true,
                },
              ],
            },
            {
              path: 'trash',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <TrashPage />
                    </Suspense>
                  ),
                  index: true,
                },
              ],
            },
            {
              path: 'cancel',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <CancelPage />
                    </Suspense>
                  ),
                  index: true,
                },
              ],
            },
            {
              path: 'order-links',
              children: [
                {
                  element: <OrderLists />,
                  index: true,
                },
              ],
            },
            {
              path: 'inventory-links',
              children: [
                {
                  element: <InventoryLinks />,
                  index: true,
                },
              ],
            },
            {
              path: 'inventory/jewellery',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <JewelleryInventoryPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddJewelleryInventoryPage />
                    </Suspense>
                  ),
                },
                {
                  path: 'billing',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <JewelleryInventoryBilling />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleJewelleryInventoryPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateJewelleryInventoryPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/sale',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <JewelleryInventoryCart />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <JewelleryInventoryHistoryPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history/:id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateJewelleryInventoryCartPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history/:id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <ViewJewelleryInventoryCartPage />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'inventory/watchName',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <WatchNamePage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddWatchNamePage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleWatchNamePage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <EditWatchNamePage />
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'inventory/repair',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <RepairPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddRepiarPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleRepairPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <EditRepairPage />
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'inventory/watch',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <WatchPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddWatchPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'billing',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <WatchBilling />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleWatchPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateWatchPage />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/sell',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <WatchCartPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <WatchHistoryPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history/:id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateWatchHistoryPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history/:id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <ViewWatchHistoryPage />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'inventory/product-category',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <ProductCategoryPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddProductCategoryPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <ViewProductCategoryPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateProductCategoryPage />
                    </Suspense>
                  ),
                },
              ],
            },

            {
              path: 'inventory/watchbilling',
              children: [
                {
                  path: 'billing',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <WatchBilling />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'inventory/pre-order',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <PreOrder />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddPreOrderPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <ViewPreOrderPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <EditPreOrderPage />
                    </Suspense>
                  ),
                },
                {
                  path: 'receipt/:id/print',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <PreOrderReceiptPage />
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'inventory/silver',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SilverPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddSilverPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'billing',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SilverSellBilling />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleSilverPage />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateSilverPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/sale',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SilverCartPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SilverHistoryPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history/:id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateSilverHistoryPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history/:id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <ViewSilverHistoryPage />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'inventory/cart',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <CartPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <InventoryBilling />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'order/jewellery',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <JewelleryOrderPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddJewelleryOrderPage />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleJewelleryOrderPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateJewelleryOrderPage />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'order/bar',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <BarOrderPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddBarOrderPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleBarPage />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateBarPage />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'order/barBilling',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <BarBillingPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddBarBillingPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <BarBillingBillPage />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateBarBillingPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <BarBillingHistory />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history/:id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateBarBillingHistory />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history/:id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <ViewBarBillingHistory />
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'order/process',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <ProcessPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddProcessPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleProcessPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateProcessPage />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'order/billing',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <BillingPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddBillingPage />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <BillingDesign />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateBillingPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <BillingHistory />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: 'history/:id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateBillingHistoryPage />
                    </Suspense>
                  ),
                },
                {
                  path: 'history/:id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <ViewBillingHistoryPage />
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'order/dispatch',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <DispatchPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddDispatchPage />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleDispatchPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateDispatchPage />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'inventory/dispatch',
              children: [
                {
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <WatchDispatchPage />
                    </Suspense>
                  ),
                  index: true,
                },
                {
                  path: 'add',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <AddWatchDispatchPage />
                    </Suspense>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <SingleWatchDispatchPage />{' '}
                    </Suspense>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <Suspense fallback={<Loader />}>
                      {' '}
                      <UpdateWatchDispatchPage />{' '}
                    </Suspense>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
  ]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>{routes}</Suspense>
    </ErrorBoundary>
  );
}
